import React from 'react';
import _ from 'lodash';
import { FaPhone } from 'react-icons/fa';
import PageHeader from '../PageHeader';
import QuoteImage from '../QuoteImage';
import data from '../../data/treatmentPhilosophy';

import styles from './treatmentPhilosophy.module.scss';

const TreatmentPhilosophy = () => {
  const background =
    'https://res.cloudinary.com/dewd4pral/image/upload/q_60/v1499182110/treatmentPhilosophy.jpg';
  const quoteImage =
    'https://res.cloudinary.com/dewd4pral/image/upload/v1609527811/letGoBookMountains.jpg';
  const phoneIcon = <FaPhone size={22} color="#FFF" />;

  const ActionCard = ({ lastStop }) => {
    return (
      <a href="tel:8014271054">
        <div className={styles.actionCard}>
          <div className={styles.cardIcon}>{phoneIcon}</div>
          {lastStop && (
            <p className={styles.actionLastStop}>
              This is your last stop. You&apos;ve finally found treatment providers who
              get&nbsp;you!
            </p>
          )}
          <p className={styles.actionCopy}>Schedule your appointment today!</p>
          <p className={styles.actionPhone}>801-427-1054</p>
        </div>
      </a>
    );
  };

  const modalities = _.map(data.modalities, (item, index) => {
    return <li key={`${item}_${index}`}>{item}</li>;
  });

  const additionalChallenges = _.map(data.additionalChallenges, (item, index) => {
    return <li key={`${item}_${index}`}>{item}</li>;
  });

  return (
    <main>
      <PageHeader
        pageCategory="About"
        pageName="Why Mindset Family Therapy?"
        headerImage={background}
      />

      <div className={styles.textContainer}>
        <h2 className={styles.header}>Specialized. Experienced. Results.</h2>

        <ul>
          <li>
            <strong>We specialize in treating anxiety, OCD and OC related disorders</strong>
            <ul>
              <li className={styles.sub}>We are NOT general practitioners</li>
            </ul>
          </li>

          <li>
            <strong>
              30+ years of successfully treating anxiety, OCD, and OC related disorders
            </strong>
            <ul>
              <li className={styles.sub}>We get results!</li>
            </ul>
          </li>

          <li>
            <strong>
              We are regular presenters at the International OCD Foundation conferences
            </strong>
          </li>
        </ul>
      </div>

      <div className={styles.actionContainer}>
        <ActionCard />
      </div>

      <div className={styles.textContainer}>
        <ul>
          <li>
            <strong>
              We provide anxiety, and OCD consultation and training for other psychotherapists
            </strong>
          </li>

          <li>
            <strong>
              We have been trained to treat anxiety, OCD, and OC related disorders by renowned
              experts in the field
            </strong>
          </li>

          <li>
            <strong> We understand your pain!</strong>
          </li>

          <li>
            <strong>We provide therapeutic, psychoeducational, and support groups for OCD</strong>
          </li>

          <li>
            <strong>
              We treat anxiety, OCD, and OC related disorders with evidence-based treatment
              modalities and stay up to date with the latest research and best treatment for OCD:
            </strong>
            <ul>
              <li className={styles.sub}>
                {' '}
                <strong>ERP</strong> (exposure and response prevention). The behavior part of
                cognitive-behavioral therapy (CBT). ERP is the gold-standard treatment for OCD.
              </li>
              <li className={styles.sub}>
                <strong>ACT</strong> (acceptance and commitment therapy). ACT is an exposure-based
                model and has ERP built into it. ACT has been shown to be effective in promoting and
                enhancing the success of ERP in individuals struggling with OCD.
              </li>
              <li className={styles.sub}>
                <strong>I-CBT</strong> (inference-based cognitive behavioral therapy). I-CBT targets
                obsessional doubt and underlying reasoning process (inferential confusion) that
                maintains obsessional doubt. Can be used in conjunction with ERP. Similar efficacy
                as ERP.
              </li>
            </ul>
          </li>
        </ul>
      </div>

      <div className={styles.actionContainer}>
        <ActionCard lastStop />
      </div>

      <br />
      <br />
      <br />
      <br />

      <QuoteImage image={quoteImage} maxWidth={560} quote={data.imageQuote} />

      <div className={styles.textContainer}>
        <div className={styles.headerSmall}>
          Our staff is also trained with the following psychotherapy modalities:
        </div>
        {modalities}
      </div>

      <div style={{ marginTop: '-60px' }} className={styles.textContainer}>
        <div className={styles.headerSmall}>Additional challenges we treat:</div>
        {additionalChallenges}
      </div>

      <div className={styles.actionContainer}>
        <ActionCard />
      </div>

      <div className={styles.textContainer}>
        <p style={{ textAlign: 'center' }} className={styles.copy}>
          <strong>
            You and your family can learn to be actively engaged in what matters most each and
            every&nbsp;day!
          </strong>
        </p>
      </div>
    </main>
  );
};

export default TreatmentPhilosophy;
