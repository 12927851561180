const treatmentPhilosophy = {
  imageQuote:
    '<p>"Between stimulus and response there is a space. In that space is our power to choose our response. In our response lies our growth and our freedom."</p><p>VIKTOR E. FRANKL</p>',
  modalities: [
    'Cognitive Behavioral Therapy (CBT)',
    'Dialectical Behavioral Therapy (DBT)',
    'Trauma-Focused Cognitive Behavioral Therapy',
    'Mindfulness Training',
    'Social Thinking',
    'Attachment Theory',
    'Solution Focused',
    'Motivational Interviewing',
    'Trust-Based Relational Intervention (TBRI)',
    'Narrative Therapy',
    'Expressive Arts Therapy',
    'Play Therapy',
    'Child-Parent Relationship Therapy',
    'Parent Coaching',
  ],
  additionalChallenges: [
    'Depression',
    'Trauma',
    'Suicidality',
    'Self-harm',
    'Compulsive sexual behavior',
    'LGBTIQA+ related issues',
    'Couples counseling',
    'ADHD',
    'Autism Spectrum Disorder challenges',
    'Attachment disorders',
    'Behavioral issues in children',
  ],
};

export default treatmentPhilosophy;
