import React from 'react';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import TreatmentPhilosophy from '../components/TreatmentPhilosophy';

export default () => (
  <React.Fragment>
    <SEO
      title="Treatment Philosophy | Mindset Family Therapy in Provo, UT"
      description="At Mindset Family Therapy we are client-centered therapists trained to use evidence-based practices. We believe that incorporating ACT (Acceptance & Commitment Therapy) is one of the best ways to help get you where you want to be. We are ready to share a wealth of knowledge from leading sources and cutting-edge research from neuroscience, psychology, and related fields."
      pathname="/philosophy"
    />
    <Layout>
      <TreatmentPhilosophy />
    </Layout>
  </React.Fragment>
);
